.wrapper {
    background-color: #272525;
    max-height: 450px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;

    canvas {
        //border: 1px solid #01D501;
        //width: 100%;
        max-height: 430px;
        object-fit: contain;
    }
}

.history {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: flex-start;
}

.history span svg {
    transform: rotate(180deg);
    height: 7px;
    margin-top: 6px;
    margin-right: 8px;
}

.results {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.results__item {
    color: #fff;
    font-size: 12px;
}

.results__item_green {
    color: #01D501;
}


.results__item_gray {
    color: #494545;
}
.wrapper {
    min-width: 290px;
    max-width: calc(100% - 40px);
    background-color: #FF3333;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    position: fixed;
    z-index: 10;
    top: 20px;
    right: 20px;
    padding: 20px;
    display: flex;
    gap: 10px;
    font-size: 18px;
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease;
}

.wrapper.active {
    opacity: 1;
    pointer-events: auto;
}

.close {
    opacity: .5;
    margin-left: auto;
    display: block;
    margin-top: 2px;
}

